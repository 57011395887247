.ui__dot
  display: block
  position: absolute
  border-radius: 100%
  cursor: pointer
  width: 40px
  height: 40px
  border: 2px solid rgba(#FFFFFF, .5)
  transition: all .2s ease-in-out

  &:before
    display: block
    position: absolute
    content: ''
    background: rgba(#D3B91B, .5)
    border-radius: 100%
    width: 20px
    height: 20px
    centerer(true, true)
    transition: all .2s ease-in-out

  &:hover, &.active
    border-color: #FFFFFF

    &:before
      background: #D3B91B

  &.active
    .ui__dot_content
      display: block

  &_close
    display: none

    @media mob
      display: block
      position: absolute
      top: 15px
      right: 15px
      width: 30px
      height: 30px
      background: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDYxMiA2MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYxMiA2MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iY3Jvc3MiPgoJCTxnPgoJCQk8cG9seWdvbiBwb2ludHM9IjYxMiwzNi4wMDQgNTc2LjUyMSwwLjYwMyAzMDYsMjcwLjYwOCAzNS40NzgsMC42MDMgMCwzNi4wMDQgMjcwLjUyMiwzMDYuMDExIDAsNTc1Ljk5NyAzNS40NzgsNjExLjM5NyAgICAgIDMwNiwzNDEuNDExIDU3Ni41MjEsNjExLjM5NyA2MTIsNTc1Ljk5NyAzNDEuNDU5LDMwNi4wMTEgICAgIiBmaWxsPSIjMjgzNzYzIi8+CgkJPC9nPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=')
      background-repeat: no-repeat
      background-position: center
      background-size: 100%

  &_content
    display: none
    right: 60px
    min-width: 300px
    padding: 30px 45px
    border-radius: 20px
    background: #FFFFFF
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5)
    animation: fadeIn .5s ease

    &:not(.top):not(.bottom)
      centerer(false, true)

    &.right
      right: auto
      left: 60px

    &.top
      bottom: 60px
      right: auto
      centerer(true, false)

    &.bottom
      top: 60px
      right: auto
      centerer(true, false)

    @media mob
      &.top,
      &.bottom
        position: fixed
        top: 50%
        left: 50%
        right: auto
        bottom: auto
        transform: translate(-50%,-50%)
        z-index: 100

  &_info
    display: flex
    align-items: center
    color: color-red

    &Numb
      font-size: rem-calc(70px)
      line-height: 1

    &Text
      position: relative
      font-size: rem-calc(14px)
      line-height: rem-calc(18px)
      transform: rotate(-90deg)
      left: -10px

  &_text
    color: color-main
    margin-top: 5px
    font-size: rem-calc(18px)
    line-height: rem-calc(22px)

  &s_wrap
    display: block
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 9

    @media mob
      &.active:before
        display: block
        content: ''
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: rgba(color-main, .77)
        z-index: 15