rem-calc(value)
  u = unit(value);
  if (u is 'rem') {
    return unit(value, u)
  } else {
    return unit(value / base-font-size, 'rem')
  }

placeholder()
  &::-webkit-input-placeholder
    {block}
  &:-moz-placeholder
    {block}
  &::-moz-placeholder
    {block}
  &:-ms-input-placeholder
    {block}

centerer($horizontal = true, $vertical = true)
  position: absolute;
  if ($horizontal and $vertical)
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
  else if ($horizontal)
    left: 50%;
    transform: translate(-50%, 0)
  else if ($vertical)
    top: 50%;
    transform: translate(0, -50%)

font-url(file, folder = '')
  if folder != ''
    return base-fonts-path + folder + '/' + file
  return base-fonts-path + file

webfont(family, file, hack-chrome-windows = false, weight = 'normal')
  @font-face
    font-family: family
    src: url(font-url(file + '.eot', family))
    src: url(font-url(file + '.eot?#iefix', family)) format('embedded-opentype'),
        url(font-url(file + '.woff', family)) format('woff'),
        url(font-url(file + '.ttf', family)) format('truetype'),
        url(font-url(file + '.svg#'+ family, family)) format('svg')
    font-weight weight
    font-style normal
    if hack-chrome-windows
      @media screen and (-webkit-min-device-pixel-ratio: 0)
        @font-face
          font-family family
          src url(font-url(file + '.svg#'+ family, family)) format('svg')