.row
  box-sizing: border-box
  display: flex
  flex: 0 1 100%
  flex-direction: row
  flex-wrap: wrap
  list-style: none
  margin-top: -1rem
  margin-bottom: 1rem
  margin-left: -($grid-padding)
  margin-right: -($grid-padding)
  padding: 0

  &:last-child
    margin-bottom: 0

  +variant(no-gutters)
    margin: 0