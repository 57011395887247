.ui__modal
  background: #FFFFFF
  padding: 50px 30px
  text-align: left
  max-width: 430px
  margin: 0 auto
  position: relative
  box-shadow: 0 26px 70px rgba(24, 31, 81, .07)

  &_title
    display: block
    font-size: rem-calc(24px)
    line-height: rem-calc(30px)
    margin-bottom: 30px

  &_row
    display: flex
    justify-content: space-between
    flex-direction: column

    &:not(:last-child)
      margin-bottom: 20px

    &.horizontal
      flex-direction: row

  &_part
    flex-grow: 1

    &:first-child
      padding-right: 15px
    &:last-child
      padding-left: 15px

  &_input
    display: block
    width: 100%

  &_btn
    display: inline-block
    margin-top: 30px
    padding: 7px 49px

  &_letter
    max-width: 830px

  &_label
    display: block

  &_logo
    max-width: 200px

    &_wrap
      margin-top: 30px



button.mfp-close
  background-image: url('../img/cross.svg')
  background-repeat: no-repeat
  background-position: center

.mfp-close
  width: 20px
  height: 20px
  right: 20px
  top: 20px
  padding: 0
  content: ''
  opacity: 1
  transition: all .25s ease

  &:active
    top: 20px

  &:hover
    opacity: .8


.mfp-bg
  opacity: 1 !important
  background: linear-gradient(125.72deg, rgba(81, 115, 178, 0.8) 0.3%, rgba(32, 32, 97, 0.8) 99.33%)


.my-mfp-zoom-in .zoom-anim-dialog
  opacity: 0
  transition: all 0.2s ease-in-out
  transform: scale(0.8)

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog
  opacity: 1
  transform: scale(1)

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog
  transform: scale(0.8)
  opacity: 0

.my-mfp-zoom-in.mfp-bg
  opacity: 0
  -webkit-transition: opacity 0.3s ease-out
  -moz-transition: opacity 0.3s ease-out
  -o-transition: opacity 0.3s ease-out
  transition: opacity 0.3s ease-out

.my-mfp-zoom-in.mfp-ready.mfp-bg
  opacity: 0.8

.my-mfp-zoom-in.mfp-removing.mfp-bg
  opacity: 0