.header
  display: flex
  align-items: center
  justify-content: space-between
  position: fixed
  top: 0
  left: 0
  padding: 40px 36px 0
  width: 100%
  z-index: 105

  @media mob
    padding: 15px 20px 0

  @media tablet
    padding: 20px 30px 0

  &__potok
    @media mob
      .header__logo
        position: relative
        left: -30px


  .row
    display: flex
    align-items: center
    margin: 0

  .col
    padding: 0

  &.invert
    @media mob
      &:not(.header__potok)
        background-image: linear-gradient(to top, rgba(40, 55, 99, 0.0), rgba(40, 55, 99, 0.5) 32%, #283763)

    .header__menu, .header__logo img
      filter: invert(100%) brightness(200%)

  &__logo
    display: inline-block

    &_wrap
      text-align: center

    img
      max-width: 100%
      transition: filter .5s ease-in-out

  &__menu
    display: block
    width: 60px
    height: 33px
    position: relative
    transform: rotate(0deg)
    transition: .5s ease-in-out
    cursor: pointer

    span
      display: block
      position: absolute
      height: 3px
      width: 100%
      background: color-main
      opacity: 1
      left: 0
      transform: rotate(0deg)
      transition: .25s ease-in-out

      &:nth-child(1)
        top: 0

      &:nth-child(2), &:nth-child(3)
        top: 15px

      &:nth-child(4)
        top: 30px

    &.open
      span
        &:nth-child(1)
          top: 15px
          width: 0
          left: 50%

        &:nth-child(2)
          transform: rotate(45deg)

        &:nth-child(3)
          transform: rotate(-45deg)

        &:nth-child(4)
          top: 15px
          width: 0%
          left: 50%


  &__right
    display: flex
    align-items: center
    justify-content: flex-end

  &__view
    display: flex
    align-items: center
    justify-content: stretch
    margin-left: 26px

    @media mob
      margin-left: 10px

    &_btn
      display: block
      padding: 10px 18px
      color: #FFFFFF
      text-align: center
      font-size: rem-calc(18px)
      border: 2px solid #FFFFFF
      transition: all .2s ease-in-out

      @media mob
        display: -webkit-box
        font-size: rem-calc(14px)
        padding: 6px 6px
        -webkit-box-orient: vertical
        overflow-x: hidden
        overflow: hidden
        max-height: 34px
        height: 34px

        &:hover
          color: #FFFFFF
          background: transparent

      &:first-child
        border-right: none
        border-top-left-radius: 32px
        border-bottom-left-radius: 32px

      &:last-child
        border-left: none
        border-top-right-radius: 32px
        border-bottom-right-radius: 32px

      &:hover, &.active
        color: color-main
        background: #FFFFFF

  &__comments
    display: block
    position: relative
    width: 40px
    height: 40px
    border-radius: 100%
    border: 2px solid #FFFFFF
    transition: all .2s ease-in-out

    &:hover, &.active
      background: #FFFFFF

      .header__comments_ico path
        fill: color-main

    @media mob
      width: 30px
      height: 30px
      min-width: 30px

      &:hover
        background: transparent

        .header__comments_ico path
          fill: #FFFFFF

      &.active
        background: #FFFFFF

        .header__comments_ico path
          fill: color-main

    &_ico
      display: block
      position: absolute
      centerer(true, true)

      @media mob
        max-width: 60%

      path
        fill: #FFFFFF
        transition: all .2s ease-in-out