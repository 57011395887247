.ui__map
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1

  &Mobile
    &_toggle
      display: none
      position: fixed
      bottom: 50px
      left: 20px
      width: 32px
      height: 32px
      background: #FFFFFF
      border-radius: 100%
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .2)
      z-index: 5

      @media mob
        display: block

    &_ico
      display: block
      position: absolute
      centerer(true, true)

      path
        fill: #6B6B6B

    &_close
      display: none
      position: absolute
      left: 20px
      top: -50px
      width: 32px
      height: 32px
      background: #FFFFFF
      border-radius: 100%
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .2)
      z-index: 5

      @media mob
        display: block


  &_loader
    display: none
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    align-items: center
    justify-content: center
    background: #e2e2e2
    z-index: 4
    animation: fadeIn .5s ease
    transition: all .5s ease-in-out

    &.active
      display: flex

  &Buttons
    position: absolute
    centerer(false, true)
    right: 36px
    max-width: 32px
    z-index: 5

    &_group
      display: flex
      position: relative
      flex-direction: column
      justify-content: center
      align-items: stretch
      border: none
      border-radius: 16px
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2)

      &:after
        display: block
        position: absolute
        content: ''
        centerer(true, true)
        width: 20px
        height: 2px
        background: #eeeeee

    &_item
      display: flex
      align-items: center
      justify-content: center
      padding: 10px
      background: #FFFFFF
      font-size: rem-calc(20px)
      font-weight: bold
      color: #6b6b6b
      transition: all .2s ease-in-out

      &:hover
        background: #fbfbfb

      &:first-child
        border-top-left-radius: 16px
        border-top-right-radius: 16px

      &:last-child
        border-bottom-left-radius: 16px
        border-bottom-right-radius: 16px

    &_btn
      display: flex
      align-items: center
      justify-content: center
      padding: 10px
      border-radius: 100%
      background: #FFFFFF
      margin-top: 15px
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2)
      transition: all .2s ease-in-out

      svg
        max-height: 12px

      path
        fill: #6b6b6b
        max-width: 100%

      &:hover
        background: #fbfbfb


  &Search
    display: none
    position: fixed
    left: 36px
    centerer(false, true)
    padding: 20px
    border-radius: 5px
    background: #FFFFFF
    animation: fadeIn .5s ease-in-out
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2)
    z-index: 5

    @media mob
      left: 0
      bottom: 0
      top: auto
      transform: none
      width: 100%
      max-height: 230px
      animation: slideDown 1s ease-in-out

    &.anim
      animation: slideUp 1s ease-in-out

    &.active
      display: block

    &_header
      margin-bottom: 30px

      @media mob
        margin-bottom: 20px

    &_title
      display: block
      margin-bottom: 10px
      font-size: rem-calc(18px)
      color: #a3a3a3

  &Filter
    display: flex
    flex-wrap: wrap
    align-items: center

    &_item
      margin: 0 5px

      &:first-child
        margin-left: 0
      &:last-child
        margin-right: 0

    &_toggle
      display: block
      padding: 10px 18px
      border-radius: 20px
      color: color-main
      font-size: rem-calc(18px)
      border: 2px solid color-main
      transition: all .2s ease-in-out

      @media mob
        padding: 8px 15px

      &:hover, &.active
        background: color-main
        color: #FFFFFF

  &List
    display: block
    position: relative
    margin-top: 15px
    max-height: 265px

    @media mob
      max-height: 75px

    &_item
      display: block
      color: color-main
      margin-bottom: 20px

      @media mob
        display: flex
        align-items: center

      &:last-child
        margin-bottom: 0

    &_name
      font-size: rem-calc(18px)
      line-height: rem-calc(24px)

      @media mob
        font-size: rem-calc(16px)
        line-height: rem-calc(24px)

    &_address
      font-size: rem-calc(14px)
      line-height: rem-calc(24px)

      @media mob
        color: #a3a3a3
        font-size: rem-calc(14px)
        line-height: rem-calc(24px)
        margin-left: 10px

    &_route
      display: block
      font-size: rem-calc(14px)
      line-height: rem-calc(24px)
      color: #248cf1
      transition: all .2s ease-in-out

      @media mob
        flex-grow: 1
        justify-self: flex-end
        text-align: right

        &.active
          color: color-red

        &:hover
          color: #248cf1

      &:hover, &.active
        color: color-red



@keyframes slideDown {
  0% {
    transform: translateY(500px)
  }
  100% {
    transform: translateY(0)
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0)
  }
  100% {
    transform: translateY(1000px)
  }
}