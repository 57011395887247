@import url('https://fonts.googleapis.com/css?family=Marmelad&subset=cyrillic')
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=cyrillic')

@import '_normalize.styl'
@import '_vars.styl'
@import '_mixins.styl'
@import 'grid/grid.styl'

//- Стандартизация
@import 'common/_elements.styl'

//- Глобальные блоки
@import 'common/_header.styl'
@import 'common/_footer.styl'

@import 'common/_modals.styl'


//- ================
//- Элементы страниц
//- ================
@import 'blocks/_menu.styl'
@import 'blocks/_dots.styl'
@import 'blocks/_preloader.styl'

//- ================
//- Стили страниц
//- ================
@import 'pages/_index.styl'
@import 'pages/_content.styl'
@import 'pages/_map.styl'

//- ================
//- Адаптив
//- ================
@import "common/_responsive.styl"