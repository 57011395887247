.ui__menu
  display: none
  align-items: center
  justify-content: center
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  animation: fadeIn .5s ease
  background: rgba(color-main, .95)
  z-index: 100

  &.open
    display: flex

  &.closing
    animation: fadeOut .5s ease

  .ui__soc
    position: absolute
    centerer(true, false)
    bottom: 30px

    @media mob
      left: auto
      right: 130px
      transform: none

    &_ico
      filter: brightness(200)
      transition: all .2s ease-in-out

  &_loader
    display: none
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    justify-content: center
    background: color-main
    align-items: center
    z-index: 5

    &.active
      display: flex

  &_helper
    display: none
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    padding: 0 30px
    font-size: 14px
    color: #d2d2d2

    @media mob
      display: flex
      align-items: center
      justify-content: space-between

  &_text
    position: relative
    top: -10px

  &_hand
    position: relative
    right: 30px
    width: 48px
    animation: hand 4s infinite ease-in-out
    transition: all .2s ease-in-out

    path
      fill: #fff

  &_wrap
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    max-width: 900px
    margin: 80px -45px 0

    @media tablet
      margin: 80px 10px 0
      justify-content: center

    @media mob
      margin-top: 40px

      .ui__menu_item
        opacity: .8
        transform: scale(.65)

      .center .ui__menu_item
        opacity: 1
        transform: scale(1)

        .ui__menu_title
          color: color-yellow

        .ui__menu_ico path
          fill: color-yellow

  &_item
    display: block
    text-align: center
    margin: 0 45px 80px
    width: 130px
    transition: all .2s ease-in-out

    @media mob
      margin: 0 auto

    &:hover
      .ui__menu_title
        color: color-yellow

      .ui__menu_ico path
        fill: color-yellow

      @media mob
        .ui__menu_title
          color: #FFFFFF

        .ui__menu_ico path
          fill: #FFFFFF

  &_ico
    display: block
    margin: 0 auto 16px
    min-height: 62px
    transition: all .2s ease-in-out

    path
      transition: all .2s ease-in-out

  &_title
    font-size: rem-calc(24px)
    line-height: rem-calc(30px)
    color: #FFFFFF
    transition: all .2s ease-in-out



@keyframes hand {
  0% {
    transform: translateX(0)
  }
  30% {
    transform: translateX(-30px)
  }
  70% {
    transform: translateX(30px)
  }
  100% {
    transform: translateX(0)
  }
}