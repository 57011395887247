*
  box-sizing border-box
  margin 0
  padding 0

body
  display: flex
  flex-direction: column
  font-family: base-font-family
  color: color-main
  line-height: base-line-height

html
  font-size: base-font-size

body, html
  padding: 0
  border: 0
  vertical-align: baseline
  height: 100%
  width: 100%

//- Выделение
::-moz-selection
  background: base-selection-color
  color: white
  text-shadow: none

::selection
  background: base-selection-color
  color: white
  text-shadow: none

// browsehappy
.browsehappy
  display: block
  margin: 0
  padding: 30px
  text-align: center
  background: color-red
  color: #FFFFFF
  font-size: rem-calc(18px)
  line-height: rem-calc(22px)

  a, a:hover
    text-decoration: none


//- Заголовки
h1, h2, h3, h4, h5, h6
  margin: 0
  font-weight: normal

//- Ссылки
a
  position: relative
  text-decoration: none

//- Списки
ul, li
  margin 0
  padding 0
  list-style-type none


//- Кнопки
button
  box-shadow: none
  border: none

  &.ui__btn
    background: transparent
    color: color-main

    &:hover
      color: #FFFFFF

.ui__btn
  text-align: center
  color: color-main
  padding: 18px 24px
  border-radius: 40px
  font-weight: normal
  border: 2px solid color-main
  transition: all .25s ease-in-out

  &:hover
    background: color-main
    color: #FFFFFF

  &.white
    border-color: #FFFFFF
    color: #FFFFFF

    &:hover
      background: #FFFFFF
      color: color-main

//- Текст
sup
  vertical-align: super

p:not(:last-child)
  margin-bottom: 25px

blockquote
  display: block
  padding: 40px
  border: 3px solid color-red
  background: #FFFFFF
  box-shadow: 0 26px 70px rgba(24, 31, 81, .07)
  font-size: rem-calc(24px)
  line-height: rem-calc(40px)
  margin-top: 22px


//- Элементы форм
label
  display: block
  font-size: rem-calc(16px)
  line-height: rem-calc(20px)
  margin-bottom: 10px

  &.error
    display: none !important

input, textarea, button
  outline none

input
  line-height: initial

  &.error, &:focus.error
    border-color: color-red

input, textarea
  background: #FFFFFF
  padding: 10px 20px
  border: 3px solid #D6DBE6
  line-height: 1
  transition: all .25s ease

  +placeholder()
    color: #D6DBE6
    opacity: 1

  &:focus
    border-color: color-main


input[type="search"]
  -webkit-appearance: none

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration
  -webkit-appearance: none

textarea
  width 100%
  resize none

  &.error, &:focus.error
    border-color: color-red

fieldset
  border none
  margin: 0
  padding: 0


//- Слайдеры
.owl
  &-dots
    display: flex
    position: absolute
    bottom: 20px
    left: 0
    width: 100%
    justify-content: center

  &-dot
    margin: 0 5px

    &:first-child
      margin-left: 0

    &:last-child
      margin-right: 0

    &.active span
      background: #FFFFFF

    span
      display: block
      width: 5px
      height: 5px
      border: 1px solid #FFFFFF
      border-radius: 100%

  &-nav
    display: flex
    position: absolute
    justify-content: space-between
    width: 100%
    top: 45%
    left: 0
    transform: translate(-50% 0)

  &-prev, &-next
    position: relative
    width: 17px;
    height: 40px;
    background: url("../img/arrow.svg")
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    transition: .35s all linear

    &.disabled
      opacity: .5
      cursor: not-allowed !important
      pointer-events: all !important

  &-prev
    left: 60px
  &-next
    right: 60px

  &-prev
    transform: scale(-1, 1)

// Checkbox
.ui__checkbox
  position: relative
  vertical-align: middle

[type=checkbox]:checked+label.ui__checkbox_lbl, [type=checkbox]:not(:checked)+label.ui__checkbox_lbl
  position: relative
  padding-left: 40px
  cursor: pointer
  display: inline-block

[type=checkbox]:checked+label.ui__checkbox_lbl:after
  opacity: 1
  transform: scale(1)

[type=checkbox]:not(:checked)+label.ui__checkbox_lbl:after
  opacity: 0
  transform: scale(0)

[type=checkbox]:checked+label.ui__checkbox_lbl:before
  background: #495AA5

[type=checkbox]:checked+label.ui__checkbox_lbl:before, [type=checkbox]:not(:checked)+label.ui__checkbox_lbl:before
  display: block
  content: ''
  position: absolute
  left: 0
  top: -2px
  width: 23px
  height: 23px
  border: 2px solid #495AA5

[type=checkbox]:checked+label.ui__checkbox_lbl:after, [type=checkbox]:not(:checked)+label.ui__checkbox_lbl:after
  display: block
  position: absolute
  content: ''
  width: 23px
  height: 23px
  background-image: url(../img/check.svg)
  background-repeat: no-repeat
  background-position: center
  top: -1px
  left: 1px
  transition: all .2s

[type=checkbox]:checked, [type=checkbox]:not(:checked)
  position: absolute
  left: -9999px


.main
  display: flex
  background: color-main
  align-items: center
  flex-grow: 1

  &.content
    display: block
    align-items: flex-start
    padding-top: 144px
    padding-bottom: 100px

    &.centerer
      display: flex
      align-items: center

      @media mob
        display: block
        align-items: flex-start

    @media mob
      display: block
      padding-top: 110px

      .wrapper
        width: auto


  &.invert
    color: #FFFFFF

  &__index
    align-items: flex-end

  .wrapper
    position: relative
    width: 100%
    margin: 0 80px
    z-index: 10

    &.no-gutter
      width: auto

    @media mob
      width: auto
      margin: 0 20px

    @media tablet
      margin: 0 30px
      overflow-x: hidden

.ui
  &__soc
    display: flex
    align-items: center

    &_ico
      margin: 0 3px

      &:first-child
        margin-left: 0

      &:last-child
        margin-right: 0


  &__address
    position: fixed
    top: 20%
    left: 35px
    padding: 20px
    border-radius: 5px
    background-color: #ffffff
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2)
    z-index: 10

    &_title
      display: block
      font-size: rem-calc(18px)
      line-height: rem-calc(20px)

    &_box
      margin-top: 20px

    &_row
      display: flex
      align-items: center
      margin-bottom: 15px
      font-size: rem-calc(14px)

      &:last-child
        margin-bottom: 0

    &_left
      width: 63px
      color: #a3a3a3
      margin-right: 20px

    &_right
      flex-grow: 1

    &_tel
      color: #343434

    &_mail
      color: #248cf1


  &__mapContacts
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1

  &__404
    display: flex
    position: fixed
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    top: 0
    left: 0
    color: #FFFFFF
    background: color-main
    z-index: 10

    &_inner
      text-align: center

    &_logo
      display: block
      margin: 0 auto 45px
      width: 280px
      height: 230px
      filter: invert(1) brightness(200)

    &_title
      display: block
      font-size: rem-calc(32px)
      line-height: rem-calc(48px)
      margin-bottom: 8px

    &_desc
      font-size: rem-calc(20px)
      line-height: 1.4

      a
        display: inline
        color: #FFFFFF
        padding-bottom: 1px
        border-bottom: 2px solid rgba(#ececec, .5)
        transition: all .2s ease-in-out

        &:hover
          color: color-yellow

  &__guide
    display: none
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    animation: fadeIn .5s ease-in-out
    background: rgba(color-main, .6)
    z-index: 600

    &.active
      display: block

    &_a
      position: absolute
      bottom: 110px
      right: 130px
      width: 267px
      height: 80px
      background: url("../img/instruction/pic1.png") no-repeat center
      background-size: cover

    &_b
      position: absolute
      centerer(true, true)
      width: 829px
      height: 418px
      background: url("../img/instruction/pic2.png") no-repeat center
      background-size: cover

    &_c
      position: absolute
      top: 105px
      right: 108px
      width: 396px
      height: 93px
      background: url("../img/instruction/pic3.png") no-repeat center
      background-size: cover

    @media screen and (max-width: 812px)
      &_a
        bottom: 60px
        right: 35px
        width: 165px
        height: 41px
        background: url("../img/instruction/pic1m.png") no-repeat center

      &_b
        centerer(false, true)
        left: 30px
        width: 249px
        height: 152px
        background: url("../img/instruction/pic2m.png") no-repeat center

      &_c
        top: 75px
        right: 30px
        width: 247px
        height: 64px
        background: url("../img/instruction/pic3m.png") no-repeat center


    &_close
      position: absolute
      bottom: 35px
      centerer(true, false)
      border-radius: 32px
      background: #FFFFFF
      padding: 18px 32px
      color: color-main
      border: 2px solid #FFFFFF
      font-size: rem-calc(24px)
      text-align: center
      transition: all .2s ease-in-out

      &:hover
        background: transparent
        color: #FFFFFF

  &__viderror
    display: none
    position: fixed
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    z-index: 500
    top: 0
    left: 0
    animation: fadeIn .5s ease-in-out
    background: rgba(color-main, .95)

    &.active
      display: flex

    &_close
      display: block
      position: absolute
      top: 20px
      right: 20px
      width: 32px
      height: 32px
      background-size: 100%
      background-repeat: no-repeat
      background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAzNzEuMjMgMzcxLjIzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNzEuMjMgMzcxLjIzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCI+Cjxwb2x5Z29uIHBvaW50cz0iMzcxLjIzLDIxLjIxMyAzNTAuMDE4LDAgMTg1LjYxNSwxNjQuNDAyIDIxLjIxMywwIDAsMjEuMjEzIDE2NC40MDIsMTg1LjYxNSAwLDM1MC4wMTggMjEuMjEzLDM3MS4yMyAgIDE4NS42MTUsMjA2LjgyOCAzNTAuMDE4LDM3MS4yMyAzNzEuMjMsMzUwLjAxOCAyMDYuODI4LDE4NS42MTUgIiBmaWxsPSIjRkZGRkZGIi8+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=')


    &_wrap
      max-width: 290px
      color: #FFFFFF
      text-align: center

      img
        display: block
        margin: 0 auto 20px
        transform: rotate(90deg)

    &_title
      display: block
      font-size: rem-calc(22px)
      line-height: rem-calc(30px)
      margin-bottom: 15px

    &_text
      line-height: rem-calc(24px)


  &__breadcrumbs
    display: flex
    align-items: center
    margin-bottom: 5px

    &_item
      display: block
      position: relative
      margin: 0 10px

      &:first-child
        margin-left: 0

      &:last-child
        margin-right: 0

      &:not(:last-child):after
        display: block
        position: absolute
        content: '/'
        right: -15px
        top: 0
        font-size: rem-calc(14px)

    &_link
      display: block
      color: #FFFFFF
      padding-bottom: 1px
      border-bottom: 1px solid rgba(#FFFFFF, .5)
      transition: all .2s ease-in-out

      &:hover
        color: color-yellow

  &__rotateScreen
    display: none
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: color-main
    z-index: 999

    @media screen and (max-width: 767px) and (orientation: portrait)
      display: block

    &_logo
      position: absolute
      top: 24px
      centerer(true, false)
      max-width: 50px

      path
        fill: #FFFFFF

    &_inner
      position: absolute
      centerer(true, true)
      text-align: center
      min-width: 250px

    &_ico
      display: block
      max-width: 90px
      margin-bottom: 20px
      margin-left: auto
      margin-right: auto

    &_text
      color: #FFFFFF

  &__video
    display: block
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1

    &_loader
      display: none
      align-items: center
      justify-content: center
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: color-main
      z-index: 11

      &.active
        display: flex

    &-covered
      &:before
        display: block
        position: absolute
        content: ''
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: rgba(#FFFFFF, .4)
        z-index: 10

  &__bgvid
    display: block
    position: absolute
    top: 50%
    left: 50%
    min-width: 100%
    min-height: 100%
    width: auto
    height: auto
    transform: translate(-50%, -50%)

    @media mob
      width: 100%

  &__nav
    display: flex
    position: fixed
    align-items: center
    justify-content: space-between
    width: calc(100% - 72px)
    left: 0
    bottom: 36px
    margin: 0 36px
    z-index: 100

    @media mob
      margin: 0
      bottom: 0
      padding: 0 20px 20px
      width: 100%

      &.content__nav
        background: linear-gradient(to bottom, rgba(40, 55, 99, 0.0), rgba(40, 55, 99, 0.5) 32%, #283763)

    @media tablet
      margin: 0
      bottom: 0
      padding: 0 30px 30px
      width: 100%

    &_right
      display: flex
      align-items: center
      justify-content: flex-end

    .row
      display: flex
      align-items: center
      margin: 0

    .col
      padding: 0

    &_title
      text-align: center
      font-size: rem-calc(40px)
      line-height: rem-calc(50px)

      @media both
        font-size: rem-calc(24px)
        line-height: rem-calc(32px)

    &_btn
      display: inline-block
      position: relative
      font-size: rem-calc(24px)
      font-weight: normal

      @media mob
        display: flex
        align-items: center
        justify-content: center
        padding: 0 !important
        width: 32px
        height: 32px

        span
          display: none

      &:hover:before
        filter: invert(100%) brightness(200%)

      &-left
        padding-left: 55px
        &:before
          display: block
          content: ''
          position: absolute
          top: 50%
          left: 15px
          width: 24px
          height: 24px
          background-size: 100%
          background: url('../img/arrow1.svg') no-repeat center center
          transform: translateY(-50%) scaleX(-1)
          transition: all .15s ease

          @media mob
            width: 16px
            height: 15px
            background-size: 100%
            background-repeat: no-repeat
            background-position: center
            centerer(true, true)
            background: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDE5OS40MDQgMTk5LjQwNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTk5LjQwNCAxOTkuNDA0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMzUuNDEyLDAgMzUuNzA5LDk5LjcwMiAxMzUuNDEyLDE5OS40MDQgMTYzLjY5NSwxNzEuMTE5IDkyLjI3Nyw5OS43MDIgMTYzLjY5NSwyOC4yODUgICIgZmlsbD0iI0ZGRkZGRiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=')

      &-right
        padding-right: 55px
        &:before
          display: block
          content: ''
          position: absolute
          top: 50%
          right: 15px
          width: 24px
          height: 24px
          background-size: 100%
          background: url('../img/arrow1.svg') no-repeat center center
          transform: translateY(-50%)
          transition: all .15s ease

          @media mob
            right: auto
            width: 16px
            height: 15px
            background-size: 100%
            background-repeat: no-repeat
            background-position: center
            transform: translateY(-50%) scaleX(-1)
            background: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDE5OS40MDQgMTk5LjQwNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTk5LjQwNCAxOTkuNDA0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMzUuNDEyLDAgMzUuNzA5LDk5LjcwMiAxMzUuNDEyLDE5OS40MDQgMTYzLjY5NSwxNzEuMTE5IDkyLjI3Nyw5OS43MDIgMTYzLjY5NSwyOC4yODUgICIgZmlsbD0iI0ZGRkZGRiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=')



.ui__nextVideo
  display: none
  position: absolute
  top: 50%
  right: 36px
  width: 64px
  height: 64px
  background-size: contain
  background-position: center
  background-repeat: no-repeat
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im00MC40LDEyMS4zYy0wLjgsMC44LTEuOCwxLjItMi45LDEuMnMtMi4xLTAuNC0yLjktMS4yYy0xLjYtMS42LTEuNi00LjIgMC01LjhsNTEtNTEtNTEtNTFjLTEuNi0xLjYtMS42LTQuMiAwLTUuOCAxLjYtMS42IDQuMi0xLjYgNS44LDBsNTMuOSw1My45YzEuNiwxLjYgMS42LDQuMiAwLDUuOGwtNTMuOSw1My45eiIgZmlsbD0iI0ZGRkZGRiIvPgogIDwvZz4KPC9zdmc+Cg==')
  transform: translateY(-50%)
  z-index: 100

  &.active
    display: block

.ui__prevVideo
  display: none
  position: absolute
  top: 50%
  left: 36px
  width: 64px
  height: 64px
  background-size: contain
  background-position: center
  background-repeat: no-repeat
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im00MC40LDEyMS4zYy0wLjgsMC44LTEuOCwxLjItMi45LDEuMnMtMi4xLTAuNC0yLjktMS4yYy0xLjYtMS42LTEuNi00LjIgMC01LjhsNTEtNTEtNTEtNTFjLTEuNi0xLjYtMS42LTQuMiAwLTUuOCAxLjYtMS42IDQuMi0xLjYgNS44LDBsNTMuOSw1My45YzEuNiwxLjYgMS42LDQuMiAwLDUuOGwtNTMuOSw1My45eiIgZmlsbD0iI0ZGRkZGRiIvPgogIDwvZz4KPC9zdmc+Cg==')
  transform: translateY(-50%) scaleX(-1)
  z-index: 100

  &.active
    display: block

.ui__video
  overflow: hidden
    
.ui__video_k
  display: block
  transition: all .9s ease
  position:relative
  width:auto
  height:100%
  
  &Wrap
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    width: 100vw
    height: 100%
    top: 0
    left: 0
    touch-action: pan-x
    cursor: -webkit-grab

@keyframes toRight {
    0% {
        opacity: 1
        filter: blur(.5)
        transform: translate3d(-50%,-50%,0)
    }
    to {
        opacity: .8
        filter: none
        transform: translate3d(150%,-50%,0)
    }
}
@keyframes toLeft {
    0% {
        opacity: 1
        filter: blur(.5)
        transform: translate3d(-50%,-50%,0)
    }
    to {
        opacity: .8
        filter: none
        transform: translate3d(-150%,-50%,0)
    }
}
@keyframes fromLeft {
    0% {
        opacity: .8
        filter: blur(.5)
        transform: translate3d(-150%,-50%,0)
    }
    to {
        opacity: 1
        filter: none
        transform: translate3d(-50%,-50%,0)
    }
}
@keyframes fromRight {
    0% {
        opacity: .8
        filter: blur(.5)
        transform: translate3d(150%,-50%,0)
    }
    to {
        opacity: 1
        filter: none
        transform: translate3d(-50%,-50%,0)
    }
}


@keyframes fadeOut {
  0% {
    opacity: 1
  }
  to {
    opacity: 0
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3,.3,.3)
  }

  50% {
    opacity: 1
  }
}

@keyframes rubberBand {
  0% {
    transform: scaleX(1)
  }

  30% {
    transform: scale3d(1.25,.75,1)
  }

  40% {
    transform: scale3d(.75,1.25,1)
  }

  50% {
    transform: scale3d(1.15,.85,1)
  }

  65% {
    transform: scale3d(.95,1.05,1)
  }

  75% {
    transform: scale3d(1.05,.95,1)
  }

  to {
    transform: scaleX(1)
  }
}