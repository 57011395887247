.grid
  box-sizing: border-box
  padding: 0 $grid-padding

  +variant(container)
    margin: 0 auto
    max-width: $grid-max-width
    width: 100%

  +variant(no-gutter s)
    padding: 0