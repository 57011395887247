make-default()
  &
    flex-grow: 1
    flex-basis: 0
    max-width: 100%
    padding: 1rem .5rem 0 .5rem

// Function to create the offset
make-offset($columns)
  &-offset-0
    margin-left: 0
  for $number in 1..$columns
      &-offset-{$number}
        margin-left: (100 / $columns * $number) %

// Function to make a single column
make-column($columns)
  for $number in 1..$columns
    &{'-'}{$number}
      width: (100 / $columns * $number) %
      flex: none

// Function to create a full column
make-grid-column($breakpoint, $columns)
  make-helpers($breakpoint)
  .col--{$breakpoint}
    make-default()
    make-column($columns)
    make-offset($columns)

// Function to initialize the grid
init($breakpoints, $columns)
  for $breakpoint, $width in $breakpoints
    if $width == 0
      make-grid-column($breakpoint, $columns)
    else
      @media (min-width: $width)
        make-grid-column($breakpoint, $columns)