.index
  max-width: 700px
  margin-bottom: 80px

  @media mob
    margin-bottom: 20px
    max-width: 650px

  &__title
    display: block
    font-size: rem-calc(72px)
    font-weight: normal
    line-height: 1.1
    margin-bottom: 30px

    @media mob
      font-size: rem-calc(24px)
      line-height: rem-calc(30px)
      font-weight: bold
      margin-bottom: 10px

      br
        display: none

  &__desc
    font-size: rem-calc(24px)
    line-height: rem-calc(34px)
    margin-bottom: 35px

    @media mob
      font-size: rem-calc(16px)
      line-height: rem-calc(24px)
      margin-bottom: 15px

  &__btn
    display: inline-block
    font-size: rem-calc(36px)
    padding: 28px 35px

    @media mob
      padding: 10px 20px
      font-size: rem-calc(16px)




.quiz
  display: none
  position: fixed
  top: 0
  left: 0
  width: 100% 
  height: 100%
  animation: fadeIn .6s ease
  z-index: 110

  @media mob
    position: relative
    height: 600px
    margin-bottom: 0px

  &.active
    display: block

  &__page
    background: #fff
    

  &__wrapper
    position: absolute
    top: 50%
    left: 50%
    width: 750px
    height: 600px
    overflow: hidden
    border-radius: 30px
    background: #ffffff
    transform: translateX(-50%) translateY(-50%)
    box-shadow: 4px 2px 11px 2px rgba(0, 0, 0, .08)
    z-index: 20

    @media mob
      transform: none
      top: 0
      left: 0
      width: 100%
      height: 600px

  &__close
    display: block
    position: absolute
    top: 30px
    right: 40px
    width: 32px
    height: 32px
    background-image: url('../img/close.svg')
    background-position: center
    background-size: contain
    background-repeat: no-repeat
    z-index: 25

  &__overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, .55)
    animation: fadeIn .5s ease
    z-index: 10

  &__inner
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 23

  &__counter
    display: none
    position: absolute
    font-size: 18px
    bottom: 40px
    left: 40px
    color: #a5a5a5
    z-index: 50

    &.active
      display: block

  &__step
    display: none

    &.active
      display: block

  &__intro
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    font-size: 55px !important
    overflow: hidden
    background: url('../img/quiz/intro_bg.jpg') no-repeat bottom center
    background-size: cover
    z-index: 10

    .quiz__h1
      font-size: 50px
      color: #283763
      z-index: 40

      @media mob
        font-size: 30px

    .quiz__desc
      font-size: 26px
      top: 110px
      z-index: 40

      @media mob
        font-size: 18px
        line-height: 1.5
        top: 90px

        strong
          display: block

    &_cow
      position: absolute
      left: 0
      bottom: 0
      width: 500px
      height: 300px
      background: url('../img/quiz/cow.png') no-repeat center left
      background-size: contain !important
      z-index: 11

    &_iphone
      position: absolute
      left: 200px
      bottom: -150px
      width: 250px
      height: 612px
      background: url('../img/quiz/iphone.png') no-repeat center center
      background-size: contain !important
      filter: drop-shadow(10px -5px 10px rgba(0,0,0,.15))
      animation: fromBottom 1s ease
      z-index: 15

  &__logo
    position: absolute
    bottom: 30px
    right: 40px
    width: 62px
    height: 55px
    background: url('../img/logo.svg') no-repeat center
    background-size: contain
    z-index: 50


  &__h1
    font-size: 24px
    font-weight: bold
    line-height: 1.5
    color: #a5a5a5
    animation: fadeIn 1.5s ease
    position: absolute
    left: 40px
    top: 40px
    padding-right: 90px

    @media mob
      font-size: 20px
      padding-right: 30px

    &-q4
      font-size: 18px

      & + .quiz__desc
        top: 120px

        @media mob
          top: 160px

        & + .quiz__answers
          top: 170px

          @media mob
            top: 200px

    &-q5
      font-size: 16px
      
      @media mob
        font-size: 14px

      & + .quiz__desc
        top: 185px

        @media mob
          top: 200px

        & + .quiz__answers
          top: 270px

  &__desc
    font-size: 20px
    font-weight: 400
    line-height: 1.5
    padding-right: 40px
    color: #283763
    animation: fadeIn 2s ease
    position: absolute
    left: 40px
    top: 140px

    @media mob
      top: 140px

    strong 
      font-weight: bold
      font-size: 30px

  &__start
    display: flex
    position: absolute
    right: 50px
    bottom: 160px
    width: 180px
    height: 180px
    align-items: center
    justify-content: center
    text-align: center
    color: #ffffff
    font-size: 24px
    line-height: 35px
    background: #988941
    box-shadow: 4px 2px 11px 2px rgba(0, 0, 0, .08)
    border-radius: 100%
    transition: all .15s ease
    z-index: 41

    @media mob
      right: auto
      left: 30px
      bottom: 200px

    &:hover
      background: #283763

  &__answers
    position: absolute
    top: 230px
    left: 40px
    width: calc(100% - 80px)
    height: 270px
    animation: fadeIn 1.5s ease
    z-index: 50

    @media mob
      top: 260px

  &__answer
    display: block
    border-radius: 5px
    color: #283763
    border: 1px solid #a5a5a5
    padding: 10px 15px
    font-size: 18px
    margin-bottom: 15px
    transition: all .15s ease

    span
      font-weight: bold

    strong 
      font-weight: 400

    &:hover
      background: #eee

    &:active
      transform: translateY(2px)

    &:last-child
      margin-bottom: 0

    &.true
      background: #988941
      border-color: #988941
      color: #fff

    &.false
      background: #bb0000
      border-color: #bb0000
      color: #fff

  &__next
    display: block
    margin-top: 25px
    font-size: 20px
    font-weight: bold
    color: green

  &__form
    position: absolute
    top: 250px
    left: 40px
    width: calc(100% - 80px)
    height: 270px
    animation: fadeIn 1.5s ease
    z-index: 50

  &__input
    display: block
    margin-bottom: 25px
    border-radius: 30px
    border-width: 2px
    width: calc(100% - 15px)

  &__final
    .quiz__desc
      @media mob
        top: 110px
        font-size: 18px

        br
          display: none


@keyframes fadeIn {
  from {
    opacity: 0
  }  
  to {
    opacity: 1
  }
}

@keyframes fromLeft {
  0% {
    transform: translateX(-1000px)
    opacity: 0
  } 
  50% {
    opacity: 0  
  }
  100% {
    transform: translateX(0)
    opacity: 1
  }
}

@keyframes fromBottom {
  0% {
    transform: translateY(1000px)
    opacity: 0
  } 
  50% {
    opacity: 0  
  }
  100% {
    transform: translateY(0)
    opacity: 1
  }
}